import React from "react";
import "./TermosDeUso.scss";

export const TermosDeUso = () => (
  <div className="container">
    <p className="mt-4 px-lg-5 termos-de-uso">
      Ao acessar o ProntLife, você concorda com os nossos{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-bold text-light termos-de-uso"
        href="https://prontlife.com.br/terms/termos_de_uso_prontlife.pdf"
      >
        Termos de Uso
      </a>{" "}
      e com a nossa{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-bold text-light termos-de-uso"
        href="https://prontlife.com.br/privacy/politica_de_privacidade_seguranca_e_uso_de_dados_prontLife.pdf"
      >
        Política de Privacidade
      </a>
      ,{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-bold text-light termos-de-uso"
        href="https://prontlife.com.br/privacy/politica_de_privacidade_seguranca_e_uso_de_dados_prontLife.pdf"
      >
        Segurança e Uso de Dados
      </a>
      .
    </p>
  </div>
);
