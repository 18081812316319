import React from "react";
import { DownloadAppButtons } from "./header/DownloadAppButtons";
import { Headline } from "./header/Headline";
import { Mockup } from "./header/Mockup";
import { HeaderCurve } from "./shapes/HeaderCurve";
import { Logo } from "./shared/Logo";
import "./Header.scss";

export const Header = ({ children }: { children?: JSX.Element }) => {
  return (
    <div>
      <nav className="navbar bg-primary pt-5">
        <div className="col-lg-4 col-xl-5 col-12 text-center">
          <Logo style={{ width: 180 }} />
        </div>
      </nav>
      <div className="row bg-primary mx-0">
        <div className="col-xs-12 col-lg-6 col-xl-6 px-0">
          <Mockup />
        </div>
        <div className="col-xs-12 col-lg-6 col-xl-6 px-0 my-lg-5">
          <Headline />
          <DownloadAppButtons />
        </div>
      </div>
      <div className="bg-primary" style={{ height: 60, marginTop: -1 }} />
      <div>
        <div className="curve-container">
          <HeaderCurve />
        </div>
        {children}
      </div>
    </div>
  );
};
