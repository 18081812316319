import React from "react";

export const FooterCurve = () => {
  return (
    <svg
      viewBox="0 0 1920 100.4"
      className="svg"
      preserveAspectRatio="none"
      style={{ maxHeight: 150, width: "100%", marginBottom: -5 }}
    >
      <polygon
        id="curve"
        fill="#4056a1"
        points="1920,100.4 1920,9.2 959,91.2 0,9.2 0,100.4 "
      />
    </svg>
  );
};
