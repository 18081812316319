import React from "react";
import { FooterCurve } from "./shapes/FooterCurve";
import { Logo } from "./shared/Logo";
import { TermosDeUso } from "./termosDeUso/TermosDeUso";

export const Footer = () => {
  return (
    <footer className="text-white-50">
      <div>
        <FooterCurve />
      </div>
      <div className="py-4 bg-primary">
        <div className="d-flex mx-sm-5 mb-3 mb-md-5 flex-column flex-md-row justify-content-md-between align-items-center">
          <div className="text-md-left text-center my-md-0 my-lg-0 my-3">
            <Logo style={{ width: 200 }} />
          </div>
          <div>
            <p className="text-light text-left">Fale com a gente</p>
            <a
              href="mailto:contato@prontlife.com.br"
              className="text-light font-weight-bold"
            >
              contato@prontlife.com.br
            </a>
          </div>
        </div>

        <TermosDeUso />
      </div>
    </footer>
  );
};
