import React from "react";
import "./ItemSingle.scss";

export const CarouselItem = ({
  title,
  text,
  image,
  imageAltText,
}: {
  title: string;
  text: string;
  image: string;
  imageAltText?: string;
}) => {
  return (
    <div className="d-flex flex-column justify-content-start align-items-center mb-3 mx-auto">
      <div className="bg-primary card-circle">
        <img alt={imageAltText || ""} className="card-icon" src={image} />
      </div>
      <h4 className="text-primary mt-3 text-center mb-2 pb-lg-3 title">
        {title}
      </h4>
      <p className="text-center description">{text}</p>
    </div>
  );
};
