import React from "react";
import "./Headline.scss";

export const Headline = () => {
  return (
    <div
      style={{ paddingBottom: 20 }}
      className="bg-primary headline text-light"
    >
      <h1 className="font-weight-bold text-lg-left text-center">
        Aperfeiçoe seu atendimento e consultas com o ProntLife Médico
      </h1>
    </div>
  );
};
