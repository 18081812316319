import React from "react";
import AgendaIcon from "../../assets/iconCarouselFeatures/agenda.svg";
import PacienteIcon from "../../assets/iconCarouselFeatures/paciente.svg";
import BularioIcon from "../../assets/iconCarouselFeatures/bulario.svg";
import { CarouselItem } from "../carousel/ItemSingle";

export const Features = () => {
  const itens = [
    <CarouselItem
      title="Agenda de consultas"
      text="Marque suas consultas diretamente pelo aplicativo e tenha seus agendamentos sempre a mão."
      image={AgendaIcon}
    />,
    <CarouselItem
      title="Cadastro de Paciente"
      text="Armazenamento, na nuvem, de todos os pacientes cadastrados."
      image={PacienteIcon}
    />,
    <CarouselItem
      title="Bulário"
      text="Busca pelo nome e bula de medicamentos dentro do próprio aplicativo."
      image={BularioIcon}
    />,
  ];
  return (
    <div className="container">
      <div className="row justify-content-center">
        {itens.map((item, index) => (
          <div key={index} className="my-sm-5 my-3 row col-xl">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
