import React from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import AppStore from "../../assets/app-store.svg";
import PlayStore from "../../assets/google-play.svg";
import "./DownloadAppButtons.scss";

const APPLE_URL =
  "https://apps.apple.com/br/app/prontlifem%C3%A9dico/id1489353060";
const GOOGLE_URL =
  "https://play.google.com/store/apps/details?id=br.com.prontlife.prestador&hl=pt_BR";

export const DownloadAppButtons = () => {
  return (
    <div className="flex-row">
      <div className="col-md bg-primary text-lg-left text-center">
        {isAndroid || !isMobile ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={GOOGLE_URL}
            onClick={() =>
              ReactGA.event({
                category: "Store",
                action: "Download",
                label: "Google",
              })
            }
          >
            <img
              src={PlayStore}
              alt="Faça o download na PlayStore"
              className="download-button"
              style={{
                marginRight: !isMobile ? 15 : 0,
              }}
            />
          </a>
        ) : null}
        {isIOS || !isMobile ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={APPLE_URL}
            onClick={() =>
              ReactGA.event({
                category: "Store",
                action: "Download",
                label: "Apple",
              })
            }
          >
            <img
              src={AppStore}
              alt="Faça o download na AppStore"
              className="download-button"
            />
          </a>
        ) : null}
      </div>
    </div>
  );
};
