import React, { useState } from "react";
import ReactGA from "react-ga";
import "./App.css";
import { Cards } from "./components/Cards";
import { CarouselParceiros } from "./components/CarouselParceiros";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import "./custom.scss";
import ThemeContext from "./context/themeContext";
import { imagesLight, imagesDark } from "./utils/mockups";
import { Features } from "./components/features/Features";
//import { VideoSection } from "./components/videoSection/VideoSection";

const App: React.FC = () => {
  ReactGA.initialize("UA-157357437-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [selectedImage, setSelectedImage] = useState(imagesLight[0]);
  const [isThemeDark, setIsThemeDark] = useState(false);
  return (
    <>
      <ThemeContext.Provider
        value={{
          isThemeDark,
          imagesLight,
          imagesDark,
          selectedImage,
          setSelectedImage,
          setIsThemeDark,
        }}
      >
        <Header>
          <Features />
        </Header>
      </ThemeContext.Provider>
      <Cards />
      {/*       <VideoSection />*/}
      <CarouselParceiros />
      <Footer />
    </>
  );
};

export default App;
