import React from "react";
export const BorderUp = () => {
  return (
    <svg
      viewBox="0 0 1920 100.4"
      className="svg"
      preserveAspectRatio="none"
      style={{ maxHeight: 150, width: "100%", marginBottom: -5 }}
    >
      <path
        id="curve"
        fill="#10C7E6"
        d="M1920,100.4H0V81.9L961,0l959,82V100.4z"
      />
    </svg>
  );
};
