import React from "react";
import "./Card.scss";

export enum Side {
  LEFT = "left",
  RIGHT = "right",
}
export const Card = ({
  noTextColor,
  title,
  text,
  image,
  side,
  noMarginBottom,
  isImageOverFlow,
}: {
  isImageOverFlow?: boolean;
  noMarginBottom?: boolean;
  noTextColor?: boolean;
  title: string;
  text: string;
  image: string;
  side: Side;
}) => {
  const imageComp = (
    <div
      className={`col-5 ${
        noMarginBottom ? "align-self-center align-self-md-end pt-5" : "py-5"
      } ${isImageOverFlow ? "image-card" : ""} ${
        side === Side.LEFT ? "text-right" : "text-left"
      } align-self-center`}
    >
      <img
        alt={title}
        src={image}
        className={`${isImageOverFlow ? "big-image" : "image"} ${
          side === Side.LEFT ? "mr-lg-5" : ""
        }`}
      />
    </div>
  );
  const textComp = (
    <div
      className={`col-7 d-flex flex-column justify-content-center py-5 mt-lg-4 ${
        side !== Side.LEFT ? "align-items-end" : ""
      }`}
    >
      <span
        className={`text-left ${
          noTextColor ? "text-light" : "text-muted"
        } text-description`}
      >
        <h4
          className={`${
            noTextColor ? "text-light" : "text-primary"
          } title-card`}
        >
          {title}
        </h4>
        {text}
      </span>
    </div>
  );
  return (
    <div className="container">
      <div className="row">
        {side === Side.LEFT ? imageComp : textComp}
        {side === Side.LEFT ? textComp : imageComp}
      </div>
    </div>
  );
};
