import React from "react";
import Medico from "../assets/cards/medico.svg";
import Celular from "../assets/cards/celular.svg";
import Formulario from "../assets/cards/formulario.svg";
import { Card, Side } from "./card/Card";
import { HeaderCurve } from "./shapes/HeaderCurve";
import { BorderUp } from "./shapes/BorderUp";

export const Cards = () => {
  return (
    <div>
      <BorderUp />
      <div style={{ background: "#10C7E6" }}>
        <Card
          isImageOverFlow={true}
          noTextColor={true}
          title="Todos os dados do paciente em um só lugar"
          text="Pelo aplicativo é possível consultar o histórico de saúde, prescrições médicas, carteira de vacinação e exames laboratoriais do paciente."
          image={Medico}
          side={Side.LEFT}
        />
      </div>

      <Card
        noMarginBottom={true}
        title="Bulário e prescrição de medicamentos"
        text="Além da consulta de bulas, podem ser inseridas novas prescrições de medicamentos pelo próprio aplicativo, definindo os horários e a dosagem a serem tomados."
        image={Celular}
        side={Side.RIGHT}
      />
      <div style={{ background: "#4056A1" }}>
        <Card
          noTextColor={true}
          title="Triagem no agendamento"
          text="Juntamente com o agendamento, a ProntLife encaminha o formulário de pré-consulta, diminuindo o tempo de espera para o atendimento."
          image={Formulario}
          side={Side.LEFT}
        />
      </div>
      <HeaderCurve />
    </div>
  );
};
