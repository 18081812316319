import React, { useContext, useEffect } from "react";
import "./Mockup.scss";
import ThemeContext from "../../context/themeContext";
import MockupImage from "../../assets/mockup.png";
export const Mockup = () => {
  const { selectedImage } = useContext(ThemeContext);
  // Preload mocks
  useEffect(() => {
    const preload = new Image();
    preload.src = selectedImage.image;
  }, [selectedImage.image]);
  return (
    <div
      id="mockup-phone"
      style={{ backgroundImage: "url(" + MockupImage + ")" }}
    />
  );
};
