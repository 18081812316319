import React from "react";
export const HeaderCurve = () => {
  return (
    <svg
      viewBox="0 0 1920 100.4"
      className="svg"
      preserveAspectRatio="none"
      style={{ maxHeight: 150, width: "100%", marginTop: -8 }}
    >
      <path id="curve" fill="#4056a1" d="M0,0h1920v18.5l-961,82L0,18.5V0z" />
    </svg>
  );
};
